.bg-tertiary {
  .nav-pills,
  .nav-tabs {
    .nav-link {
      color: $body-color;

      &.active {
        background-color: theme-color(primary);
        border-color: theme-color(primary);
        color: white;
      }
    }
  }

  .nav-tabs {
    .nav-link {
      &:hover,
      &:active,
      &:focus {
        border-bottom-color: transparent;
      }
    }

    border-color: theme-color(primary);
  }
}
