.table {
  thead {
    th {
      border-bottom-color: rgba(0,0,0,.1);
    }
  }

  td,
  th {
    border-top-color: rgba(0,0,0,.1);
  }
}
